
import {IonContent, IonItem, IonLabel, IonList, IonPage} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Admin',
  components: {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonLabel
  }
});
